export function parseBoolean(value: string | undefined | boolean): boolean {
	if (value === true) {
		return value
	}
	return value === 'true'
}

export function parseString(value: string | undefined, defaultValue: string = ''): string {
	return value ?? defaultValue
}
