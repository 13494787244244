
const GridDebugger = () => {

    return (
        <div className="grid-overlay">
            <div className="grid-overlay__wrapper">
                {[...Array(12)].map((x: any, i: number) => {
                    return (
                        <div key={`grid-column--${i}`} className="grid-overlay__column">
                            <div className="grid-overlay__column-inner"></div>
                        </div>
                    )
                })}
            </div>
            <div className="grid-overlay__secondary-grid-container">
                <div className="grid-overlay__secondary-grid-wrapper">
                    {[...Array(24)].map((x: any, i: number) => {
                        return (
                            <div key={`secondary-grid-column--${i}`} className="grid-overlay__secondary-grid-column">
                                <div className="grid-overlay__secondary-grid-column-inner"></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}
export default GridDebugger
