import { useState, useEffect } from 'react'
import type { AppProps } from 'next/app'

import "../sass/style.scss"

import GridDebugger from '../components/debug/gridDebugger'
import Script from 'next/script'

const PLAUSIBLE_PROXY_URL = process.env.NEXT_PUBLIC_PLAUSIBLE_CLOUDFRONT_PROXY

function MyApp({ Component, pageProps }: AppProps) {

	const [ isGridVisible, setIsGridVisible ] = useState(false)

	useEffect(() => {

		function handleKeyUp( event: any ) {
			if (event.ctrlKey && event.which == 71) {
				setIsGridVisible(!isGridVisible)
			}
		}

		document.addEventListener("keyup", handleKeyUp)
		return () => {
			document.removeEventListener("keyup", handleKeyUp)
		}

	}, [isGridVisible])

	return (
		<>
			<Component {...pageProps} />
			{PLAUSIBLE_PROXY_URL &&
				<Script
					defer
					data-domain="arctic.com"
					src={`${PLAUSIBLE_PROXY_URL}/js/script.js`}
				/>
			}
			{process.env.NEXT_PUBLIC_APP_ENV !== 'production' && isGridVisible && <GridDebugger />}
		</>
	)
}
export default MyApp
