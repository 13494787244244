var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"HI3-P1dzpFoRQAc8aXki6"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { parseBoolean, parseString } from './lib/helpers/parseUtils'

const SENTRY_DSN: string = parseString(process.env.NEXT_PUBLIC_SENTRY_DSN)
const SENTRY_ENABLED: boolean = parseBoolean(process.env.NEXT_PUBLIC_SENTRY_ENABLED)
const SENTRY_DEBUGGING: boolean = parseBoolean(process.env.NEXT_PUBLIC_SENTRY_DEBUGGING)
const ENVIRONMENT: string = parseString(process.env.NEXT_PUBLIC_APP_ENV, 'development')

Sentry.init({
	dsn: SENTRY_DSN,
	tunnel: '/api/sentry',
	enabled: SENTRY_ENABLED,
	debug: SENTRY_DEBUGGING,
	environment: ENVIRONMENT,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.1,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysOnErrorSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
})
